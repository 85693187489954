import Input from "@/components/input";
import axios from "@/axios";
import loader from "@/components/loader";
import Button from "@/components/button";
import SubHeader from "@/components/SubHeader";

export default {
    name: "create-package",
    components: {
        "neo-input":Input,
        Button,
        axios,
        loader,
        SubHeader,
    },
    mounted(){
        
    }, 
    title: 'Create Package',

    data() {
        return {
            packageCreationInProgress:false,
            package:{
                package_name:'',
                package_name_internal:'',
                package_description:''
            }
        };
    },
    methods: {
        async createPackage(){
            
            try{
                let url = `/screening-package`;
                this.packageCreationInProgress = true
                await axios.post(url, this.package);
                this.$toast.success("Package created");
                this.$router.push(
                    {
                        "name":"Screening Package"
                    }
                )
            }
            catch(error){
                this.$toast.warning(error.response.data.detail  ,"Could not create package!");
            }

            this.packageCreationInProgress = false
        }
    },
};


